import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import nosPlatformesContent from '../config/contents/nos-platformes';

import '../assets/css/fenan-pro.css';

const NosPlatformesPage: React.FC<PageProps<any>> = ({ data }) => {
  const { heroBg, site, } = data;
  const { title, path, description } = site?.siteMetadata?.pages?.nosPlatformes;

  return (
    <Layout>
      <SEO title={title} description={description} path={path} image={heroBg?.childImageSharp.fluid.src} />

      <div className="nos-platformes__block">

        <section className="d-block position-relative">

          <div className="container position-relative">
            <div className="container__row row justify-content-start justify-content-md-end">
              <div className='mt-5 pt-5 d-flex flex-column justify-content-center text-white'>
                <h1 className="title mt-0 mb-4 pr-2 d-bold">
                  {nosPlatformesContent.section1.title}
                </h1>
                <p className='font-weight-light fsz-20 m-0 p-0 pr-0 pr-md-2'>{nosPlatformesContent.section1.description}</p>
              </div>
            </div>
          </div>

        </section>

        <section className='py-5 list-group'>
          <div className="container">
            <div className="container__row row">

              <ul className="p-0 m-0 text-white list-group w-100">
                {
                  nosPlatformesContent.section2.map(item => <li key={item} className='list-group-item bg-transparent'>{item}</li>)
                }
              </ul>
            </div>
          </div>
        </section>

      </div>

    </Layout>
  );
}

export const query = graphql`
  query PlatformesQuery {
    site {
      siteMetadata {
        lang
        pages {
          nosPlatformes {
            title
            path
            description
          }
        }
      }
    }
    heroBg: file(relativePath: { eq: "fenan-pro/hero-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    productsImages: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "fenan-pro/products"}}) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 235) {
              ...GatsbyImageSharpFluid_withWebp
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default NosPlatformesPage;
