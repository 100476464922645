const nosPlatformesContent = {
  section1: {
    title: `Nos platformes`,
    description: `Nous distribuons directement aux plateformes suivantes, qui distribuent ensuite leurs catalogues à de nombreux petites plateformes. Veuillez également noter que votre sortie pourrait ne pas apparaître sur tous les sites Web de ces détaillants, car il pourrait s'agir d'une boutique avec des restrictions de genre ou de label. Cette liste est susceptible d'être modifiée.`,
  },
  section2: [
    `163.com`,
    `24-7`,
    `AKAZOO`,
    `Amazon `,
    `Anghami  `,
    `Audible (Audiobooks only)`,
    `AWA`,
    `Beatport (Electronic music only)`,
    `Bookwire (Audiobooks only)`,
    `Claromusica`,
    `Deezer`,
    `eMusic`,
    `Facebook`,
    `ExLibris`,
    `Google Play`,
    `Gracenote`,
    `Gube Music (Classical music only)`,
    `Highresaudio (High-res audio only)`,
    `IDAGIO (Classical music only)`,
    `Instagram`,
    `iTunes / Apple Music`,
    `Juno (Electronic music only)`,
    `KKBOX`,
    `Melon`,
    `Mondia Media`,
    `Music Island`,
    `Napster`,
    `Naver Music`,
    `NetEase`,
    `Pandora`,
    `Qobuz`,
    `Saavn`,
    `Shazam`,
    `simfy Africa`,
    `Spotify`,
    `Tidal`,
    `TikTok`,
    `Traxsource (Electronic music only)`,
    `Vodafone`,
    `Weltbild`,
    `Yandex Music`,
    `YouTube Content ID`,
    `YouTube Music`,
    `Zvooq`,
  ]
};

export default nosPlatformesContent;